/*******************************************************************
    This file houses configuration utils.  Complex sorting/etc.
 *******************************************************************/


 /**
  * Generates the necessary dropdown options for Semantic Dropdown when given the fieldsettings (which contains the field type), and the sorted and grouped fields array.
  * @param {Object} fieldSettings Field setting from the plugin configuration
  * @param {Object} sortedAndGroupedFields Sorted and grouped fields - object with keys equal to the type of field.  The value of each property is an array of allowable fields for that input.
  * @return {Object} Fields to show in the dropdown based on this particular fieldSettings types
  */
export const generateFieldListByFieldType_h = (fieldSettings, sortedAndGroupedFields)=>{
    var dropDownOptions = [];


    if( fieldSettings.fieldTypes ) {
        
        fieldSettings.fieldTypes.forEach(type=>{
            //read permissions will allow only virtual/formula fields to be displayed in a dropdown.  Read/Write will allow both regular and virtual fields.  Write permissions will allow only non formula fields
            switch (fieldSettings.permissions) {
                case 'r':
                    dropDownOptions = dropDownOptions.concat(sortedAndGroupedFields[`r${type}`]);
                    break;
                case 'w':
                    dropDownOptions = dropDownOptions.concat(sortedAndGroupedFields[type]);
                    break;
                case 'rw':
                    dropDownOptions = dropDownOptions.concat(sortedAndGroupedFields[type], sortedAndGroupedFields[`r${type}`]);
                    break;
                default:
                    break;
            }
            
        });
    } else {
        //IF field types is not provided in the fieldsettings for this input, then it is assumed all fields should be shown from Quickbase
        for (var key in sortedAndGroupedFields) {
            if (sortedAndGroupedFields.hasOwnProperty(key)) {
                dropDownOptions = dropDownOptions.concat(sortedAndGroupedFields[key]);
            }
        }
    }

    return dropDownOptions;
};

/**
 * This is to sort and group the fields by quick base type.  This allows only the specific fields in question to be shown in the dropdowns.
 * @param {Object} fieldsArray Array of objects of fields in question.
 * @returns {Object} Object with properties containing all the field types.
 */
export const sortDBFields_h = (fieldsArray) => {

    console.log("FIELDS__________________________________", fieldsArray);
    
    var fields = {
        //read/write fields
        address: [],
        text: [],
        numeric: [],
        currency: [],
        url:[],
        date: [],
        timestamp: [], //date/time from qb
        duration: [],
        user: [],
        multiuser:[],
        checkbox: [],
        file: [],
        phone: [],
        email: [],
        timeofday: [],
        rating: [],
        recordid:[],
        "rich-text":[],
        "text-multiple-choice":[],
        percent:[],
        dblink:[],
        "text-multi-line":[],
        multitext:[],

        //read only (formula fields)
        raddress: [],
        rtext: [],
        rnumeric: [],
        rcurrency: [],
        rurl: [],
        rdate: [],
        rtimestamp: [], //date/time from qb
        ruser: [],
        rmultiuser:[],
        rcheckbox: [],
        rfile: [],
        rphone: [],
        remail: [],
        rtimeofday: [],
        rrating: [],
        rrecordid: [],
        "rrich-text":[],
        "rtext-multiple-choice":[],
        rpercent:[],
        rdblink:[],
        "rtext-multi-line":[],
        rduration:[],
        rmultitext: []

    };

    fieldsArray.forEach(( field ) => {
        
        var fieldObject = {
            key: field.id,
            text: field.label,
            value: field.id
        };
        let isCompositeField = field.properties.parentFieldId ? true : false;

        switch (field.fieldType) {
            
            case ("address"):
                if (field.properties && field.properties.compositeFields && field.properties.compositeFields.length > 0) {
                    field.properties.compositeFields.forEach(compositeField=>{
                        var compositeFieldObject = {
                            key: compositeField.id,
                            text: `${field.label}: ${compositeField.key}`,
                            value: compositeField.id
                        };
                        if (field.mode) {
                            fields.raddress.push(compositeFieldObject);
                        } else {
                            fields.address.push(compositeFieldObject);
                        }
                    });
                }
                break;
            case ("text"):
                //composite fields are address fields which are handled above
                if (!isCompositeField){
                    if (field.mode) {
                        fields.rtext.push(fieldObject);
                    } else {
                        fields.text.push(fieldObject);
                    }
                }
                break;
            case ("numeric"):
                if (field.mode) {
                    fields.rnumeric.push(fieldObject);
                } else {
                    fields.numeric.push(fieldObject);
                }
                break;
            case ("currency"):
                if (field.mode) {
                    fields.rcurrency.push(fieldObject);
                } else {
                    fields.currency.push(fieldObject);
                }
                break;
            case ("url"):
                if (field.mode) {
                    fields.url.push(fieldObject);
                } else {
                    fields.url.push(fieldObject);
                }
                break;
            case ("date"):
                if (field.mode) {
                    fields.rdate.push(fieldObject);
                } else {
                    fields.date.push(fieldObject);
                }
                break;
            case ("timestamp"):
                if (field.mode) {
                    fields.rtimestamp.push(fieldObject);
                } else {
                    fields.timestamp.push(fieldObject);
                }
                break;
            case ("user"):
                if (field.mode) {
                    fields.ruser.push(fieldObject);
                } else {
                    fields.user.push(fieldObject);
                }
                break;
            case ("multiuser"):
                if (field.mode) {
                    fields.rmultiuser.push(fieldObject);
                } else {
                    fields.multiuser.push(fieldObject);
                }
                break;
            case ("checkbox"):
                if (field.mode) {
                    fields.rcheckbox.push(fieldObject);
                } else {
                    fields.checkbox.push(fieldObject);
                }
                break;
            case ("file"):
                if (field.mode) {
                    fields.rfile.push(fieldObject);
                } else {
                    fields.file.push(fieldObject);
                }
                break;
            case ("phone"):
                if (field.mode) {
                    fields.rphone.push(fieldObject);
                } else {
                    fields.phone.push(fieldObject);
                }
                break;
            case ("email"):
                if (field.mode) {
                    fields.remail.push(fieldObject);
                } else {
                    fields.email.push(fieldObject);
                }
                break;
            case ("timeofday"):
                if (field.mode) {
                    fields.rtimeofday.push(fieldObject);
                } else {
                    fields.timeofday.push(fieldObject);
                }
                break;
            case ("rating"):
                if (field.mode) {
                    fields.rrating.push(fieldObject);
                } else {
                    fields.rating.push(fieldObject);
                }
                break;
            case ("recordid"):
                if (field.mode) {
                    fields.rrecordid.push(fieldObject);
                } else {
                    fields.recordid.push(fieldObject);
                }
                break;
            case ("rich-text"):
                if (field.mode) {
                    fields["rich-text"].push(fieldObject);
                } else {
                    fields["rrich-text"].push(fieldObject);
                }
                break;
            case ("text-multiple-choice"):
                //composite fields are address fields which are handled above
                if (!isCompositeField) {
                    if (field.mode) {
                        fields["text-multiple-choice"].push(fieldObject);
                    } else {
                        fields["rtext-multiple-choice"].push(fieldObject);
                    }
                }
                break;
            case ("text-multi-line"):
                if (field.mode) {
                    fields["text-multi-line"].push(fieldObject);
                } else {
                    fields["rtext-multi-line"].push(fieldObject);
                }
                break;
            case ("percent"):
                if (field.mode) {
                    fields.percent.push(fieldObject);
                } else {
                    fields.rpercent.push(fieldObject);
                }
                break;
            case ("dblink"):
                if (field.mode) {
                    fields.dblink.push(fieldObject);
                } else {
                    fields.rdblink.push(fieldObject);
                }
                break;
            case ("duration"):
                if (field.mode) {
                    fields.duration.push(fieldObject);
                } else {
                    fields.rduration.push(fieldObject);
                }
                break;
            case ("multitext"):
                if (field.mode) {
                    fields.multitext.push(fieldObject);
                } else {
                    fields.rmultitext.push(fieldObject);
                }
                break;
            default:
            // code block
        }
    });
    return fields;
};