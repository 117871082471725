import React, {useState, useEffect} from "react";
//redux
import { useSelector } from "react-redux";

import { Link, useHistory, useLocation } from 'react-router-dom';



// Styles
import styles from "./index.module.css";

// Components
import {
    Button,
    Checkbox,
    Grid,
    Header,
    Modal,
    List,
    Icon,
    Divider,
    Popup,
    Container
} from "semantic-ui-react";

import ImageCarousel from '../../ImageCarousel/ImageCarousel';

import PluginRating from '../Rating/PluginRating.js';
import RelatedDocumentation from "../../RelatedDocumentation/RelatedDocumentation";
import { formatToUSD_h } from "../../../utils/currencyHelpers";
import PluginTierPricing from "../../PluginTierPricing/PluginTierPricing";
import VideoTutorialPlayer from "../../VideoTutorialPlayer/VideoTutorialPlayer";
import HelmetSeo from "../../HelmetSeo/HelmetSeo";
import { resetSemanticModalBugFix_h } from "../../../utils/semanticHelpers";


/**
 * Used to showcase a plugins details - shows in a modal.  note that this component is rendered in the "routes" area of this application - it's conditionally rendered based on state in react-router-dom.  See the "PluginBriefCard" component for details about how this state is operating and also view the "routes" for this component. 
 * @param {Object} props Component props
 * @param {object} props.plugin A particular plugin object from the databas
 * 
 */
function PluginDetailModal({plugin}) {


    /*************************************************
     *  Redux
     *************************************************/
    //current user logged in
    const { user }                         = useSelector(state => state);
    const { isLoggedIn } = useSelector(state => state.auth);


    
    /*************************************************
     *  General Component Setup
     *************************************************/
    const history       = useHistory();
    const location      = useLocation();

    //obtain user manual URL and video url.
    const userManualUrl = plugin.documentation ? plugin.documentation.productSheet : null;
    const videoUrl      = plugin.documentation ? plugin.documentation.video : null;
    //set the format of the ratings (provide decimal place if there isn't one)
    const setRatingFormat = rating => {
        if (!rating || rating === 0) {
            return 0;
        }
        return rating.toFixed(1);
    };


    /*************************************************
     *  State
     *************************************************/
    var [billingToggle, setBillingToggle]         = useState(true);
    var [prereqModalOpen, setPrereqModalOpen]     = useState(false);
    var [userHasFreeTrial, setUserHasFreeTrial]   = useState(false);
    var [promoVidModalOpen, setPromoVidModalOpen] = useState(false);

    /*************************************************
     *  Effects
     *************************************************/
    //checks if user still has a free trial for this plugin & if the plugin supports a free trials
     useEffect(()=>{
         if (plugin.freeTrial > 0) {
             if(user) {
                 if (!user.freeTrials) {
                    setUserHasFreeTrial(true);
                 } else if (user.freeTrials && !user.freeTrials[plugin._id]) {
                    setUserHasFreeTrial(true);
                 } else {
                    setUserHasFreeTrial(false);
                 }
             } else {
                userHasFreeTrial(true);
             }
         } else {
             setUserHasFreeTrial(false);
         }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[user, plugin])

    // todo:find a better solution for this.  It's a hack to fix a semantic bug
    //this is a hack - but it fixes a bug in semantic ui react.  IF you're sitting on a page with this modal open, then refresh the page, there is a scrolling issue that happens and it doesn't allow you to scroll down the modal.  This code below corrects that by running only if the referrer is the current href.  This usually happens on a page refesh, but COULD happen is someone clicks a plugin twice back to back.
    useEffect(()=>{
        // console.log("Referrer ", "\n" + document.referrer, "\n"+ document.location.href);
        // if (performance.navigation.type == performance.navigation.TYPE_RELOAD) {
        //     console.info("This page is reloaded");
        // }
        resetSemanticModalBugFix_h();
    }, []);
    

    /*************************************************
     *  Handlers
     *************************************************/
    const handleInstallNowClick = e =>{
        if (isLoggedIn) {
            if (plugin.pluginInstallationPrerequisites) {
                setPrereqModalOpen(true);
            } else {
                history.push(`/config/connection/${plugin._id}`);
            }
        } else {
            //send user to login and append nextURL for easy navigation back to the plugin of interest
            let rurl = encodeURIComponent(`/marketplace/${plugin.seo.productName}`);
            history.push(`/login?nexturl=${rurl}`);
        }
        
    };

    const handleVideoToggle = (bool) => {
        setPromoVidModalOpen(bool);
    };

    //used to close the modal
    const handleClose = e => {
        e.stopPropagation();
        history.push('/marketplace/all-plugins');
    };


    

    return (
        <Modal 
            open={ location.state.pluginModalIsOpen }
            centered = { false }
            size = { "large" }
            onClose= {handleClose}
        >
            < Modal.Content >
                
                {/* SEO Optimization */}
                { plugin.seo && plugin.seo.title && plugin.seo.description &&
                    <HelmetSeo 
                        title={plugin.seo.title}
                        description={plugin.seo.description}
                    />
                }
                {/* SEO Optimization */}


                {/* image carousel  */}
                <div className={styles['detail-modal--image-holder']}>
                    <ImageCarousel
                        images={plugin.images}
                    />
                </div>
                {/* image carousel  */}


                {/* Promo Video  */}
                <VideoTutorialPlayer
                    videos={[{
                        id: videoUrl,
                    }]}
                    open={promoVidModalOpen}
                    autoplay={true}
                    openHandler={handleVideoToggle}
                />
                {/* Promo Video  */}
                

                {/* prereq modal - shows if plugin has prerequisites to complete before install  */}
                <Modal
                    centered={false}
                    closeIcon
                    open={prereqModalOpen}
                    onClose={() => setPrereqModalOpen(false)}
                    onOpen={() => setPrereqModalOpen(true)}
                >
                    <Header icon='exclamation triangle' content='Installation Prerequisites' />
                    <Modal.Content>
                        <p>
                            This plugin has some prerequisites that need to be completed before you install it.  These prerequisites are detailed by the author of the plugin below.  Please ensure these are completed before you attempt to go through the configuration process.  Click continue below to confirm you have completed these.
                        </p>
                        <List ordered>
                            {plugin.pluginInstallationPrerequisites &&
                                plugin.pluginInstallationPrerequisites.map((prereq, i) => <List.Item key={i}>{prereq}</List.Item>)
                            }
                        </List>
                        
                        {/* configuration video walkthrough  */}
                        {plugin && plugin.documentation && plugin.documentation.configVideoWalkthrough &&
                            <>
                                <Divider />
                                <Header as="h4">Installation Help Video:</Header>
                                <RelatedDocumentation
                                    userManualUrl={null}
                                    videoUrl={plugin.documentation.configVideoWalkthrough}
                                    videoLabel="Want help walking through the entire installation process for this plugin?  Click here!"
                                    documentLabel={null}
                                />
                            </>
                        }
                        {/* configuration video walkthrough  */}

                    </Modal.Content>
                    <Modal.Actions>
                        <Button 
                            color='grey' 
                            onClick={()=>setPrereqModalOpen(false)}
                        >
                            <Icon name='remove' />Cancel
                        </Button>
                        <Link to={`/config/connection/${plugin._id}`}>
                            <Button 
                                color='teal'
                            >
                                <Icon name='checkmark' />Continue
                            </Button>
                        </Link>
                    </Modal.Actions>
                </Modal>
                {/* prereq modal - shows if plugin has prerequisites to complete before install  */}

               

                <Modal.Description>
                    <Header as="h1" className={styles['detail-modal--header']}>{plugin.name}</Header>
                    <Header as="h2" className={styles['detail-modal--plugin-type']}>Quickbase Plugin/Add-on</Header>
                </Modal.Description>

                <Grid>
                    <Grid.Row>
                        <Grid.Column mobile={16} tablet={10} computer={11} largeScreen={11} widescreen={11}>

                            <article className={"mb-medium"}>

                                <div className={styles['detail-modal--rating-wrapper']}>
                                    <PluginRating
                                        rating={plugin.rating}
                                    />
                                    <span>{setRatingFormat(plugin.rating)} out of {plugin.numRatings} ratings</span>
                                </div>

                                <RelatedDocumentation
                                    userManualUrl={userManualUrl}
                                    videoUrl={videoUrl}
                                    onShowVideoClick={e=>setPromoVidModalOpen(true)}
                                    videoLabel="View Demo Video"
                                    documentLabel="View Plugin Product Sheet"
                                />

                                <Modal.Description>
                                    <Header className={styles['detail-modal--underlined-header']}>
                                        Short Description
                                            </Header>
                                    <p>
                                        {plugin.shortSummary}
                                    </p>
                                </Modal.Description>

                                <Modal.Description>
                                    <Header className={styles['detail-modal--underlined-header']}>
                                        KEY POINTS
                                            </Header>

                                    <List bulleted>
                                        {plugin.keyPoints &&
                                            plugin.keyPoints.map((keyPoint, index) => (
                                                <List.Item key={index}>
                                                    {keyPoint}
                                                </List.Item>
                                            ))
                                        }
                                    </List>
                                </Modal.Description>

                                <Modal.Description>
                                    <Header className={styles['detail-modal--underlined-header']}>
                                        DETAILED OVERVIEW
                                    </Header>

                                    {/* description array - each array item is a paragraph */}
                                    {plugin.description &&
                                        plugin.description.map((paragraph, index) =>
                                            (<p key={index}>
                                                {paragraph}
                                            </p>)
                                        )
                                    }

                                </Modal.Description>

                            </article>

                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={6} computer={5} largeScreen={5} widescreen={5}>
                                
                                {/* regular installation  */}
                                {plugin.configTemplate.configSections && !plugin.proInstall &&
                                    <div className={styles["hide-button"]}>
                                        <Button size={"large"} fluid={true} primary
                                            onClick={handleInstallNowClick}
                                        >
                                            {userHasFreeTrial ?
                                                `FREE ${plugin.freeTrial} DAY TRIAL` :
                                                "INSTALL NOW"
                                            }
                                        </Button>
                                    </div>
                                }

                                {/* pro install installation  */}
                                {plugin.proInstall &&
                                    <>
                                        <Header textAlign="center" as="h4" color="teal">
                                            <Popup
                                                inverted
                                                trigger={
                                                    <span className={styles["proinstall-details"]}>
                                                        <Icon name="info circle" />WHAT IS A PRO INSTALL PLUGIN
                                                    </span>
                                                }
                                                content={
                                                    <>
                                                        A "Pro Install" plugin is a plugin that is installed outside of the Oasis environment.<br /> <br />
                                                        Pro Install plugins have a one-time installation fee (detailed below).  After this one-time fee the plugins are billed on a monthly or annual basis (detailed below). <br /> <br />
                                                        After submitting the request by clicking the "Pro Install" button below, an MCF Technology Solutions employee will contact you to schedule and complete the installation process for you.
                                                    </>
                                                }
                                                on='click'
                                                position='top right'
                                            />
                                            
                                        </Header>
                                        <Link to={`/contact/${plugin._id}?proinstall=true`} >
                                            <Button size={"large"} fluid={true} primary>
                                                PRO INSTALL
                                            </Button>
                                        </Link>
                                    </>
                                }
                                {/* pro install installation  */}
                            <br />
                            
                            
                            <Link to={`/contact/${plugin._id}`} >
                                <Button size={"large"} fluid={true} color={"green"}>
                                    ASK A QUESTION
                                </Button>
                            </Link>
                            

                            <br />
                            
                            
                            {/* one time fee */}
                            { plugin.pricing && plugin.pricing.oneTimeFee &&
                                <Popup
                                    inverted
                                    trigger={
                                        <Container textAlign="center" className={styles["onetime-fee--popup"]}>
                                            <Icon name="info circle" color="grey" />Pro Install One-Time Fee: <strong>{formatToUSD_h(plugin.pricing.oneTimeFee)}</strong>
                                        </Container>
                                    }
                                    content="Pro Install plugins have a one-time fee which is required for the installation process.  After the one-time fee, the plugins are billed on a monthly or annual basis."
                                    on='click'
                                    position='top right'
                                />
                            }
                            {/* one time fee  */}

                            {/* flat rate billing  */}
                            {plugin.pricing.type === "flat" &&
                                <>
                                    <div className={styles['toggle']}>
                                        <span
                                            className={`${styles['toggle--labels']} ${!billingToggle && styles['toggle--label-bold']}`}>
                                            Monthly
                                    </span>
                                        <Checkbox
                                            className={styles['toggle--checkbox']}
                                            onChange={() => setBillingToggle(!billingToggle)}
                                            toggle
                                            checked={billingToggle}
                                        />
                                        <span
                                            className={`${styles['toggle--labels']} ${billingToggle && styles['toggle--label-bold']}`}>
                                            Annually
                                    </span>
                                    </div>
                                    <p className={'text-center text-bold mb-0'}>
                                        {!billingToggle && formatToUSD_h(plugin.pricing.monthlyPricing)}{billingToggle && formatToUSD_h(plugin.pricing.annualPricing)} / {!billingToggle && "month"}{billingToggle && "year"}
                                    </p>
                                    <p className={'mt-0 text-center'}>
                                        <small>Billed Monthly or Annually</small>
                                    </p>
                                    <p className={'mt-0 text-center'}>
                                        <small>*Bulk options available upon request</small>
                                    </p>
                                </>
                            }
                            {/* flat rate billing  */}



                            {/* tier based billing */}
                            {plugin.pricing.type === "tier" &&
                                <PluginTierPricing
                                    plugin={plugin}
                                />
                            }
                            {/* tier based billing */}


                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Content >
        </Modal >
    );

}

export default PluginDetailModal;